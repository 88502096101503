.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2.5rem;
}
.logo {
  max-width: 8.75rem;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pay {
  max-width: 16em;
}
.copyright {
  margin-block-start: 2rem;
  font-size: 0.7em;
  line-height: 1.6;
  text-align: center;
}

@media (max-width: 768px) {
  .pay {
    max-width: none;
  }
}
