[data-dark-theme] .root {
  color: var(--text-light);
}

.root {
  composes: round from '../../utils.css';
  --theme-color: var(--theme-primary);
  --text-dark: black;
  --text-light: white;
  display: inline-flex;
  align-items: center;
  gap: 0.625em;
  padding: 0.5em 1em;
  border: 1px solid transparent;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--text-dark);
  background: var(--theme-color);
  cursor: pointer;
}
.root[data-color='black'] {
  --theme-color: black;
  color: var(--text-light);
}
.root[data-color='white'] {
  --theme-color: white;
  color: var(--text-dark);
}
.root[data-variant='ghost'] {
  border-color: currentColor;
  color: var(--theme-color);
  background: none;
}
.root[data-variant='text'] {
  padding: 0;
  border: none;
  background: none;
}
.root[data-block] {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-block: 0.75em;
  font-weight: 600;
  border-radius: 0.625em;
}
.root[disabled] {
  cursor: not-allowed;
  opacity: calc(1 - 0.618);
}
.root:not([disabled]):hover {
  opacity: 0.9;
}

.root path {
  fill: currentColor;
}
