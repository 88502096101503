.root {
  display: grid;
  min-height: 10rem;
}
.root[data-inline] {
  display: inline-grid;
  min-height: revert;
}
.root::after {
  content: '';
  height: 1em;
  aspect-ratio: 1;
  border: 1.5px solid transparent;
  border-top-color: currentColor;
  margin: auto;
  border-radius: var(--border-circle);
  animation: turn 1.5s linear infinite;
}

@keyframes turn {
  to {
    transform: rotate(1turn);
  }
}
