.root {
  display: flex;
  align-items: center;
  gap: 0.5em;
  flex-grow: 1;
}
.logo {
  height: 2.5em;
  aspect-ratio: 1;
  object-fit: contain;
  padding: 0.25em;
  background: white;
}
