.header {
  padding-block: 0.9em;
  border-bottom: 1px solid #e9edf2;
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.account {
  display: flex;
  align-items: center;
  gap: 0.25em;
  font-weight: 600;
}
.title {
  composes: title line-height-fixed from '../../utils.css';
}

@media (max-width: 900px) {
  .header-content {
    justify-content: flex-end;
  }
  .title {
    display: none;
  }
}
