.round {
  border-radius: 0.5em;
}
.circle {
  border-radius: 50%;
}

.fullscreen {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.title {
  font-size: 2rem;
  font-family: Nunito, var(--font-family);
}

.line-height-fixed {
  line-height: var(--line-height-fixed);
}

/* TODO: find a more elegant way. */
.invert,
.invert
  :is(button:not([data-color]):not([data-variant]), img, input[type='checkbox']),
.invert ::after {
  filter: invert(1);
}

.text-cut {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
