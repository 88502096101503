.main {
  padding-inline: 21.23%;
}
.header {
  margin-block-end: 1.5rem;
}
.title {
  composes: title from '../../utils.css';
  margin-block-end: 0.1em;
  line-height: 1.4;
}
.sign-up {
  font-size: 0.8rem;
}
.actions {
  margin-block-start: 1.5rem;
  font-size: 0.8rem;
}
.forgot {
  margin-block-start: 1em;
}

@media (max-width: 768px) {
  .main {
    padding: 0;
  }
}
