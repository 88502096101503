.root {
  display: inline-flex;
  gap: 0.5em;
  font-weight: 600;
}
.control {
  width: 1.1em;
  height: 1.1em;
  font-size: 1em;
  background: var(--gray-light);
  border-radius: 0.25em;
}
