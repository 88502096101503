.root {
  composes: round from '../../utils.css';
  max-width: 100%;
  height: 2.875em;
  border: 1px solid var(--gray-border);
  padding-inline: 1em;
  font-size: 0.8rem;
  color: currentColor;
  background: none;
}
.root[data-block] {
  display: block;
  width: 100%;
}
