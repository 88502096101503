/* Third-party overrides */

.toast-container {
  font-size: 0.7em;
}

/* Reset */

*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ol,
ul {
  list-style: none;
}

/* Global */

html {
  --font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  --gray-text-primary: #6e6f7a;
  --gray-text-secondary: #95969c;
  --gray-border: #e0e7ed;
  --gray-light: #f6f8fa;
  --theme-primary: #fbf328;
  --theme-secondary: #fffcd4;
  --border-round: 0.5em;
  --border-circle: 50%;
  --line-height-fixed: 1.45rem;

  font: 1.25em var(--font-family);
}

@media (max-width: 768px) {
  html {
    --line-height-fixed: normal;
  }
}
