.header {
  margin-block-end: 1rem;
}
.title {
  composes: title from '../../utils.css';
  line-height: 1.4;
}
.skips-purchase-tip {
  margin-block-end: 2em;
}
.welcome {
  line-height: 1.5;
  text-align: center;
  margin-block-end: 2em;
}
.footnote {
  font-size: small;
}
