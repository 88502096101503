.group {
  margin-block-end: 2rem;
}
.group-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-end: 1em;
  cursor: pointer;
}
.folder {
  inline-size: 1em;
  block-size: 1em;
}
.folder path {
  stroke: currentColor;
}
.group-title:hover {
  color: var(--theme-primary);
}

.group[data-folded] {
  opacity: 0.618;
}
.group[data-folded] .folder {
  rotate: -0.25turn;
}
.group[data-folded] .list {
  display: none;
}

.item {
  composes: round from '../../utils.css';
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid var(--gray-border);
  margin-block-end: 0.75rem;
  cursor: pointer;
}
.item[data-selected] {
  color: black;
  background: var(--gray-light);
}
.info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
.price {
  text-align: end;
}
.price-number {
  min-width: 2.6em;
  font-weight: 600;
  font-size: 2.4rem;
}
.per {
  font-size: 0.7rem;
}
.name {
  font-weight: 600;
}
.duration {
  margin-block-start: 0.43em;
  font-weight: 500;
  font-size: 0.7rem;
}

.list[data-large] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
.list[data-large] .item {
  display: revert;
  padding: 1.2rem;
  margin-block-end: 0;
  border-width: 2px;
}
.list[data-large] .item:only-child {
  grid-column-start: 2;
}
.group .list[data-large] .item:only-child {
  grid-column-start: revert;
}
.list[data-large] .item[data-selected] {
  color: white;
  background: #14142b;
}
.list[data-large] .type {
  float: inline-end;
  padding: 0.5em 0.75em;
  margin: -0.5em -0.5em 0 2em;
  font-size: 0.7em;
  color: #6e7191;
  background: var(--gray-border);
  border-radius: 0.57em;
}
.list[data-large] .item[data-selected] .type {
  color: inherit;
  background: #6e7191;
}
.list[data-large] .info {
  flex-direction: column;
  align-items: revert;
  font-weight: 600;
}
.list[data-large] .name {
  font-weight: bold;
  font-size: 1.2em;
}
.list[data-large] .price {
  display: flex;
  gap: 0.25em;
  text-align: revert;
  order: 1;
  font-size: 0.9em;
}
.list[data-large] .price > * {
  font-size: 1em;
}
.list[data-large] .price-number {
  min-width: revert;
}
.list[data-large] .description {
  order: 1;
  font-size: 0.7em;
}
.list[data-large] .select {
  display: none;
}
.list[data-large] .data {
  margin-block-start: 1em;
  font-size: 0.7em;
  line-height: 1.6;
}
.list[data-large] .data > :nth-child(odd) {
  float: inline-start;
  margin-inline-end: 0.25em;
}

@media (max-width: 768px) {
  .item {
    display: revert;
    text-align: center;
  }
  .info {
    display: revert;
  }
  .price {
    text-align: inherit;
  }
  .select {
    margin-block-start: 1rem;
  }
  .list[data-large] {
    grid-template-columns: auto;
  }
  .list[data-large] .item {
    text-align: revert;
  }
  .list[data-large] .info {
    display: flex;
  }
}
