.root {
  display: block;
  font-size: 0.8rem;
  margin-block-end: 1.625em;
}
.label,
.description {
  margin-block-end: 0.625em;
  font-weight: 600;
}
.label:nth-last-child(n + 3) {
  margin-block-end: 0.5em;
  font-size: 1.25em;
}
.controls {
  display: flex;
  align-items: center;
  gap: 1em;
}
