.root {
  position: relative;
}
.backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: initial;
}
.trigger {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.trigger svg {
  width: auto;
  height: 1em;
  margin-inline-start: 0.25em;
}
.list {
  composes: round from '../../utils.css';
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 100%;
  padding-block: 0.5em;
  font-weight: initial;
  font-size: 0.75rem;
  line-height: 2.5;
  white-space: nowrap;
  color: var(--gray-text-secondary);
  background: white;
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
}
.item {
  padding-inline: 1em;
  cursor: pointer;
}
.item:hover {
  background: var(--gray-light);
}
.item[data-danger] {
  color: #ff644f;
}
.item[data-selected] {
  color: black;
}
