.root[data-style='default'] .tabs {
  margin-block-end: 2.4em;
}
.root[data-style='default'] .tab {
  position: relative;
  font-weight: 600;
  color: var(--gray-text-secondary);
}
.root[data-style='default'] .tab::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 0.2em);
  width: 0.6em;
  height: 0.6em;
  margin: auto;
  border-radius: var(--border-circle);
}
.root[data-style='default'] .tab:not(:first-child) {
  margin-inline-start: 2.4em;
}
.root[data-style='default'] .tab[data-active] {
  color: unset;
}
.root[data-style='default'] .tab[data-active]::after {
  background: var(--theme-primary);
}
.root[data-style='custom'] .tab {
  display: contents;
}
.tabs {
  composes: line-height-fixed from '../../utils.css';
  display: flex;
  align-items: center;
}
.tabs[data-scrollable] {
  overflow: scroll;
}
.tab {
  cursor: pointer;
}

@media (max-width: 768px) {
  .tabs {
    text-align: center;
  }
  .tabs:not([data-scrollable]) {
    justify-content: center;
    flex-wrap: wrap;
  }
  .tab {
    flex: 1;
  }
}
