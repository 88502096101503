.item {
  composes: line-height-fixed round from '../../utils.css';
  display: flex;
  align-items: center;
  min-height: 5em;
  border: 1px solid var(--gray-text-primary);
  margin-block-end: 0.75em;
}
.date {
  min-width: 27.4%;
  font-weight: bold;
  text-align: center;
}
.divider {
  width: 1px;
  height: 2.5em;
  background: currentColor;
}
.data {
  padding-inline: 1em;
  font-size: 0.8em;
}
.name {
  margin-block-end: 0.2em;
  font-weight: 500;
  line-height: initial;
}
.time {
  font-weight: 300;
}
