.root {
  font-weight: 600;
  margin-block-start: 3rem;
  display: flex;
  justify-content: space-between;
  padding-block: 1.5rem;
  border-block-start: 1px solid var(--gray-border);
}
.items {
  flex: 1;
  font-weight: 600;
  font-size: 0.8rem;
}
.caption {
  font-size: 1.5em;
  text-align: start;
}
.name {
  text-align: start;
}
.total {
  font-size: 2.4rem;
}
.total:only-child {
  flex: 1;
  text-align: end;
}

@media (max-width: 768px) {
  .root {
    display: revert;
  }
  .items {
    width: 100%;
    margin-block-end: 1em;
  }
  .total {
    text-align: end;
  }
}
