.root {
  max-width: 1125px;
  min-height: 100vh;
  margin: auto;
}
.main {
  display: flex;
  align-items: flex-start;
  padding-block: 2.25em;
}
.market {
  flex: 1;
  min-width: 0;
  padding-inline: 1.5em;
}
.days {
  margin-block-end: 3em;
}
.selected-day {
  composes: line-height-fixed from '../../utils.css';
  display: flex;
  align-items: center;
  gap: 0.4em;
  margin-block-end: 0.5em;
  font-weight: 600;
  color: var(--gray-text-primary);
}
.day {
  composes: round line-height-fixed from '../../utils.css';
  min-width: 2.4em;
  padding-block: 1em;
  border: 1px solid var(--gray-border);
  margin-inline-end: 0.9em;
  font-weight: 200;
  text-align: center;
  color: var(--gray-text-secondary);
}
[data-active] > .day {
  font-weight: initial;
  border-color: var(--theme-primary);
  background: var(--theme-secondary);
}
.day-date {
  font-weight: bold;
  color: var(--gray-text-secondary);
}
.divider,
.my {
  position: sticky;
  inset-block-start: 1rem;
}
.divider {
  width: 1px;
  height: 30em;
  background: var(--gray-border);
}
.my {
  width: 38%;
  padding-inline: 1.5em;
}

@media (max-width: 768px) {
  .header-content {
    display: revert;
    text-align: center;
  }
  .title {
    margin-block-end: 0.5em;
  }
  .account {
    justify-content: center;
  }
  .selected-day {
    display: revert;
    text-align: center;
  }
  .day {
    margin: 0.45em;
  }
  .main {
    display: revert;
  }
  .divider {
    display: none;
  }
  .my {
    width: auto;
    display: revert;
  }
  .book {
    position: fixed;
    inset-inline: 0;
    inset-block-end: 0;
  }
  .book[data-block] {
    width: auto;
    border-radius: revert;
  }
}
