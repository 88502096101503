.root {
  margin-block-end: 4em;
}
.header {
  margin-block-end: 0.75em;
  font-weight: 600;
}
.title {
  composes: line-height-fixed from '../../utils.css';
  margin-block-end: 0.5em;
  font-size: 1em;
  color: var(--gray-text-primary);
}
