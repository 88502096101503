.root {
  display: inline-block;
  padding: 0.2em 0.3em;
  font-weight: 300;
  font-size: 0.5em;
  color: #aca718;
  background: var(--theme-secondary);
  border-radius: 0.4em;
}
.root[data-casing='upper'] {
  font-variant-caps: all-petite-caps;
}
