.root {
  cursor: pointer;
}
a.root {
  color: inherit;
}
.root[data-underline] {
  text-decoration: underline;
}
.root[data-bold] {
  font-weight: bolder;
}
