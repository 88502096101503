.root {
  position: relative;
  display: inline-block;
}
.root input {
  position: absolute;
  right: 0;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  transform: scale(1.3);
  cursor: pointer;
}
.root svg {
  display: block;
}
