.root {
  max-width: 1600px;
  padding: 0 8%;
  margin: auto;
}
.root:has([data-selected]) .form-wrap {
  position: sticky;
  bottom: 0;
  padding-block: 1rem;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);
  animation: rise 0.2s;
}
.goods {
  margin-block-start: 2rem;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pay {
  max-width: 16em;
  margin: auto;
}

@keyframes rise {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: none;
  }
}

@media (max-width: 768px) {
  .pay {
    max-width: none;
  }
}
