.root {
  composes: fullscreen from '../../utils.css';
  z-index: 1;
  display: grid;
  backdrop-filter: blur(4px);
}
.backdrop {
  composes: fullscreen from '../../utils.css';
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
}
.main {
  --top-bar-height: 5rem;
  position: relative;
  width: min(66.4%, 47.8rem);
  max-height: 90%;
  margin: auto;
  overflow: auto;
  overscroll-behavior: contain;
}
.main::before {
  content: '';
  display: block;
  height: var(--top-bar-height);
  background: var(--theme-primary);
}
.content {
  padding: 3rem 4rem;
  color: white;
  background: black;
}
.closer {
  --height: calc(var(--top-bar-height) * 0.4);
  position: absolute;
  left: 0;
  right: 0;
  top: calc((var(--top-bar-height) - var(--height)) / 2);
  justify-content: center;
  height: var(--height);
  aspect-ratio: 1;
  margin: auto;
  font-size: calc(var(--height) * 0.8);
  text-shadow: 0 0 2px white;
}

.light {
  composes: invert from '../../utils.css';
}

@media (max-width: 768px) {
  .main {
    width: auto;
    max-height: none;
    height: auto;
    margin: 1rem;
  }
  .content {
    padding: 1rem;
  }
}
