.methods {
  display: flex;
  gap: 1em;
  padding: 1em;
}
.methods label {
  display: flex;
  align-items: center;
  gap: 0.25em;
  font-size: 0.8em;
}
